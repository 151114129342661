import React, { useState } from "react";
import "./Registration.css"; // Keep the global styles for consistency
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function RegisterPage() {
  const [formData, setFormData] = useState({
    name: "",
    whatsapp: "",
    language: "",
  });

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const whatsappRegex = /^\d{10}$/;
    if (!whatsappRegex.test(formData.whatsapp)) {
      alert("Please enter a valid 10-digit WhatsApp number.");
      return;
    }

    setLoading(true);
    setErrorMessage("");

    const requestData = {
      name: formData.name,
      whatsapp_number: formData.whatsapp,
      user_language: formData.language,
    };

    try {
      const response = await fetch(
        "https://api.stocksamarth.in/register_user",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      const result = await response.json();

      if (response.ok) {
        toast.success(
          <div>
            <p>{result.message}</p>
            <a
              href={result.link}// Change this to your desired URL
              style={{
                color: "#6a0dad", // Link color
                textDecoration: "underline",
                fontWeight: "bold",
              }}
              target="_blank" // Opens in a new tab
              rel="noopener noreferrer"
            >
              Go to Stocklens.ai
            </a>
          </div>
        );
        // Reset form data after successful registration
        setFormData({
          name: "",
          whatsapp: "",
          language: "",
        });
      } else {
        setErrorMessage(`Error in registration. ${result.detail}`);
        console.error("API Error:", result);
      }
      setFormData({
        name: "",
        whatsapp: "",
        language: "",
      });
    } catch (error) {
      console.error("Network error:", error);
      setErrorMessage("There was a problem with the network request.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="register-container">
      <div className="top-bar">
        <h1 className="header-text">
          Register for <span className="brand">Stocksamarth.ai</span>
        </h1>
      </div>

      <form className="register-form" onSubmit={handleSubmit}>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          placeholder="Name"
          required
          disabled={loading}
        />
        <input
          type="tel"
          id="whatsapp"
          name="whatsapp"
          value={formData.whatsapp}
          onChange={handleInputChange}
          maxLength="10"
          pattern="\d{10}"
          title="Please enter a valid 10-digit WhatsApp number."
          placeholder="WhatsApp Number"
          required
          disabled={loading}
        />
        <select
          id="language"
          name="language"
          value={formData.language}
          onChange={handleInputChange}
          required
          disabled={loading}
        >
          <option value="">Preferred Language</option>
          <option value="english">English</option>
          <option value="hindi">Hindi</option>
          <option value="bengali">Bengali</option>
          <option value="telugu">Telugu</option>
          <option value="marathi">Marathi</option>
          <option value="tamil">Tamil</option>
          <option value="gujarati">Gujarati</option>
          <option value="urdu">Urdu</option>
          <option value="kannada">Kannada</option>
          <option value="odia">Odia</option>
          <option value="malayalam">Malayalam</option>
          <option value="punjabi">Punjabi</option>
          <option value="assamese">Assamese</option>
          <option value="sanskrit">Sanskrit</option>
          <option value="maithili">Maithili</option>
          <option value="dogri">Dogri</option>

          {/* Add other language options here */}
        </select>

        <button type="submit" disabled={loading}>
          {loading ? "Submitting..." : "Submit"}
        </button>
      </form>

      {loading && (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      )}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
}

export default RegisterPage;
