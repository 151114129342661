import React from "react";
import "./Description.css"; // Add custom styles if needed

const Tnc = () => {
  return (
    <div className="disclaimer-container relative flex flex-col items-center justify-center">
      <h2>Terms and Conditions</h2>
      <p>
        Last updated on Oct 23 2024
      </p>
      <p>
        For the purpose of these Terms and Conditions, the term "we", "us", "our" used anywhere on this page shall mean Stocklens.ai, whose registered/operational office is Trimurti Apartment sector 14, Tower 2 Flat 102, Near CA Circle Udaipur RAJASTHAN 313002. "you", "your", "user", "visitor" shall mean any natural or legal person who is visiting our website and/or agreed to purchase from us.
      </p>
      <h3>Your use of the website and/or purchase from us are governed by the following Terms and Conditions:</h3>
      <ul>
        <li>
          The content of the pages of this website is subject to change without notice.
        </li>
        <li>
          Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
        </li>
        <li>
          Your use of any information or materials on our website and/or product pages is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through our website and/or product pages meet your specific requirements.
        </li>
        <li>
          Our website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance, and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.
        </li>
      </ul>
    </div>
  );
};

export default Tnc;
