import React from 'react';
import StockLensLogo from '../assets/stocklens_logo.webp'; // Ensure the path is correct
import './Navbar.css'; // Import the CSS file for styling

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={StockLensLogo} alt="Logo" />
        <span>Stocksamarth.ai</span>
      </div>
      <div className="navbar-menu-icon">
        <div className="menu-line"></div>
        <div className="menu-line"></div>
        <div className="menu-line"></div>
      </div>
    </nav>
  );
};

export default Navbar;
