import React from "react";
import "./Offerings.css";
import AnnualReport from "../assets/annual_report_icon.webp"; // Assuming you create a separate CSS file for styling
import RegionalLanguage from "../assets/regional_language_icon.webp";
import WhatsappBot from "../assets/whatsapp_icon.webp";

const Offerings = () => {
  return (
    <div className="offerings-container">
      <div className="relative flex flex-col items-center justify-center">
        <p className="caption5 yellow-gradient-spaced-text">OUR OFFERINGS</p>
        <p className="headerPartnerRegular text-center w-[80%]">
          How do we help you?
        </p>
      </div>
      <div className="icon-container">
        <img src={WhatsappBot} alt="Icon" />
      </div>
      <h2 className="offerings-description">Whatsapp Bot</h2>
      <p className="offerings-text">
      You can ask any stock-related question about Nifty 500 stocks in our WhatsApp bot. It can also provide comparisons between different stocks, all in one place.
      </p>
      <div className="icon-container">
        <img src={AnnualReport} alt="Icon" />
      </div>
      <h2 className="offerings-description">Annual Reports</h2>
      <p className="offerings-text">
        A concise summary of the stock's annual report. No need to go through
        the entire report.
      </p>
      <div className="icon-container">
        <img src={RegionalLanguage} alt="Icon" />
      </div>
      <h2 className="offerings-description">Regional Language</h2>
      <p className="offerings-text">
        Would you prefer fundamental analysis or responses in your regional
        language?
      </p>
    </div>
  );
};

export default Offerings;
