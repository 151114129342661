import React from "react";
import "./Disclaimer.css"; // Add custom styles if needed
import { Link } from "react-router-dom";

const Disclaimer = () => {
  return (
    <div className="disclaimer-container relative flex flex-col items-center justify-center">
      <h2>Disclaimer</h2>
      <p>
        Stocksamarth.ai makes no warranties or representations, express or implied,
        on products and services offered through the platform. It accepts no
        liability for any damages or losses, however caused, in connection with
        the use of, or reliance on its advisory or related services.
      </p>
      <p>
        Past performance is not indicative of future returns. Please consider
        your specific investment requirements, risk tolerance, goals, time
        frame, risk and reward balance, and the costs associated with the
        investment before choosing a fund or designing a portfolio that suits
        your needs. Performance and returns of any investment portfolio can
        neither be predicted nor guaranteed.
      </p>

      <div className="disclaimer-container relative flex flex-col items-center justify-center">
        <h2>Important Links</h2>
        <Link to="/terms-and-conditions">
          <p>Terms and Conditions</p>
        </Link>
        <Link to="/cancellation-and-refund">
          <p>Cancellations And Refund</p>
        </Link>
        <Link to="/privacy-policy">
          <p>Privacy Policy</p>
        </Link>
        <Link to="/pricing">
          <p>Pricing</p>
        </Link>
        <Link to="/contact-us">
          <p>ContactUs</p>
        </Link>
      </div>
    </div>
  );
};

export default Disclaimer;
