import React from "react";
import "./Description.css"; // Add custom styles if needed

const ContactUs = () => {
  return (
    <div className="disclaimer-container relative flex flex-col items-center justify-center">
    <h2>Contact Us</h2>
    <p>
      Last updated on Oct 23 2024
    </p>
    <p>
      For any queries or concerns, please reach out to us at:
    </p>
    <p>
      Stocksamarth.ai@gmail.com
    </p>
    <p>address</p>
    <p>
      House17, Near Ca circle (313002), Udaipur, Rajasthan, India
    </p>
  </div>
  );
};

export default ContactUs;
