import React from "react";
import "./Description.css"; // Add custom styles if needed

const CancellationAndRefund = () => {
  return (
    <div className="disclaimer-container relative flex flex-col items-center justify-center">
      <h2>Cancellation And Refund</h2>
      <p>
        Last updated on Oct 23 2024
      </p>
      <p>
        No Cancellation and refund policy is available at the moment. Please contact us for more information.
      </p>
    </div>
  );
};

export default CancellationAndRefund;
