import React from "react";
import "./Description.css"; // Add custom styles if needed

const PrivacyPolicy = () => {
  return (
    <div className="disclaimer-container relative flex flex-col items-center justify-center">
      <h2>Privacy Policy</h2>
      <p>Last updated on Oct 23 2024</p>
      <h3>Privacy Policy for Stocksamarth.ai</h3>
      <p>
        At Stocksamarth.ai, we are committed to safeguarding your privacy. This Privacy
        Policy outlines how we collect, use, disclose, and protect your
        information when you use our services to conduct fundamental stock
        analysis and make informed investment decisions.
      </p>

      <h4>1. Information We Collect</h4>
      <p>
        To provide you with personalized stock analysis and insights, we may
        collect the following types of information:
      </p>
      <ul>
        <li>
          <strong>Personal Information:</strong> When you create an account, we
          may collect your name, email address, and other contact information.
        </li>
        <li>
          <strong>Financial Information:</strong> While using our platform for
          stock analysis, we may collect data regarding your preferences,
          interests, and interaction with specific companies or stocks.
        </li>
        <li>
          <strong>Usage Data:</strong> We collect information on how you access
          and use Stocksamarth.ai, including your device type, IP address, browser
          type, and interaction patterns.
        </li>
        <li>
          <strong>Cookies and Tracking Technologies:</strong> We use cookies to
          personalize your experience and track how you navigate our platform.
          You can manage your cookie preferences in your browser settings.
        </li>
      </ul>

      <h4>2. How We Use Your Information</h4>
      <p>We use the information collected to:</p>
      <ul>
        <li>
          Provide tailored stock analysis, financial insights, and
          recommendations based on your preferences.
        </li>
        <li>Improve our platform by analysing user trends and behaviours.</li>
        <li>Monitor and improve the security of our platform.</li>
        <li>Comply with legal obligations and enforce our terms of service.</li>
      </ul>

      <h4>3. Sharing Your Information</h4>
      <p>
        We value your privacy and will not sell or share your personal
        information with third parties, except in the following situations:
      </p>
      <ul>
        <li>
          <strong>Service Providers:</strong> We may share your information with
          trusted third-party service providers who assist us in operating our
          platform, such as hosting services or analytics providers.
        </li>
        <li>
          <strong>Legal Compliance:</strong> If required by law or in response
          to legal processes, we may disclose your information to government
          authorities or relevant third parties.
        </li>
        <li>
          <strong>Business Transfers:</strong> In the event of a merger,
          acquisition, or sale of assets, your information may be transferred as
          part of that transaction.
        </li>
      </ul>

      <h4>4. Data Security</h4>
      <p>
        We take reasonable measures to protect your personal information from
        unauthorised access, alteration, or disclosure. However, no method of
        transmission over the Internet is 100% secure. We cannot guarantee the
        absolute security of your data.
      </p>


      <h4>5. Children's Privacy</h4>
      <p>
        Stocksamarth.ai is not intended for use by individuals under the age of 18. We
        do not knowingly collect personal information from children. If we
        discover that we have inadvertently collected such information, we will
        take steps to delete it.
      </p>

      <h4>6. Changes to This Privacy Policy</h4>
      <p>
        We may update this Privacy Policy from time to time. We will notify you
        of any significant changes by posting the new policy on our platform or
        by sending you an email. Please review this Privacy Policy periodically.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
