import React from 'react';
import { Link } from 'react-router-dom'; 
import './Main.css';

const Main = () => {
  return (
    <div className="download-section">
      <div className="exhilarating-text">
        <h2>
        Empower your investment decisions with clear, accessible fundamental analysis.
        </h2>
      </div>
      <Link to="/register">  {/* Use Link to navigate to the register page */}
        <button className="download-btn">
          <span>Register</span>
        </button>
      </Link>
    </div>
  );
};

export default Main;
