import React from "react";
import "./Description.css"; // Add custom styles if needed

const Pricing = () => {
  return (
    <div className="disclaimer-container relative flex flex-col items-center justify-center">
      <h2>Pricing</h2>
      <p>
        Last updated on Oct 23 2024
      </p>
      <p>
        First 10 stock-related questions are free for all users. After that, a subscription is required.
      </p>
      <p>
        payment link will be sent to user over Whatsapp
      </p>
      <p>
        For accessing personalized stock-related answers, a subscription is
        available at a cost of <strong>₹30 per month</strong>. This subscription allows you to
        ask questions related to stocks.
      </p>
    </div>
  );
};

export default Pricing;
