import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Import necessary components from react-router-dom
import "./global.css";
import Navbar from "./components/Navbar";
import Main from "./components/Main";
import Offerings from "./components/Offerings";
import Disclaimer from "./components/Disclaimer";
import RegisterPage from "./components/RegisterPageV2";
import Tnc from "./components/Tnc";
import CancellationAndRefund from "./components/CancellationAndRefund";
import PrivacyPolicy  from "./components/PrivacyPolicy";
import Pricing from "./components/Pricing";
import ContactUs from "./components/ContactUs";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <Router>
      <div
        className="text-gray-100 relative dark-theme antialiased"
        style={{ overflow: "unset" }}
      >
        <Navbar />
        <Routes>
          {/* Main Page Routes */}
          <Route
            path="/"
            element={
              <>
                <Main />
                <Offerings />
                <Disclaimer />
              </>
            }
          />
          {/* Register Page Route */}
          <Route path="/register" element={
          <>
          <RegisterPage />
          <ToastContainer
                  position="center" // Center the toast
                  autoClose={false} // Prevent auto close
                  hideProgressBar // Hide the progress bar
                  closeOnClick={false} // Prevent clicking to close
                  className="toast-container" // Add a custom class
                  toastClassName="dark-toast" // Custom style for the toast itself
                />
          </>
          }/>
          {/* Terms and Conditions Route */}
          <Route path="/terms-and-conditions" element={
          <>
          <Tnc />
          </>
          }/>  
          {/* Cancellation and Refund Route */}
          <Route path="/cancellation-and-refund" element={
          <>
          <CancellationAndRefund />
          </>
          }/>
          {/* Privacy Policy Route */}
          <Route path="/privacy-policy" element={
          <>
          <PrivacyPolicy />
          </>
          }/>
          {/* Pricing Route */}
          <Route path="/pricing" element={
          <>
          <Pricing />
          </>
          }/>
          {/* Contact Us Route */}
          <Route path="/contact-us" element={
          <>
          <ContactUs />
          </>
          }/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;